<mat-icon mat-button class="close" [mat-dialog-close]="true"
  ><span class="svg-icon svg-icon-1">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <rect
        opacity="0.5"
        x="6"
        y="17.3137"
        width="16"
        height="2"
        rx="1"
        transform="rotate(-45 6 17.3137)"
        fill="black"
      ></rect>
      <rect
        x="7.41422"
        y="6"
        width="16"
        height="2"
        rx="1"
        transform="rotate(45 7.41422 6)"
        fill="black"
      ></rect>
    </svg> </span
></mat-icon>

<div class="d-flex justify-content-between">
  <h2 mat-dialog-title>Activity Properties: {{ description }}</h2>
</div>

<mat-dialog-content [formGroup]="form">
  <input hidden matInput formControlName="workflowid" />
  <input hidden matInput formControlName="formid" />
  <input hidden matInput formControlName="nodeid" />
  <div class="w-100 mb-8 mt-5">
    <div class="notice d-flex bg-secondary border-primary border p-2">
      <button
        (click)="close()"
        class="btn btn-icon btn-sm btn-default icon-chevron-left navbar-btn mt-1"
        role="link"
        data-original-title="Back"
        aria-expanded="true"
      >
        <span class="sr-only">Back</span>
      </button>

      <div class="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
        <div class="mb-3 mb-md-0 fw-bold">
          <h4 class="text-gray-900 fw-bolder">
            Workflow Activity -
            {{ description }} for [ {{ formname }} ]
          </h4>
        </div>
      </div>
    </div>
  </div>

  <div class="mb-10">
    <div class="row">
      <div class="col-xl-2 text-right">
        <div class="fs-6 fw-bold mt-5 required">Name</div>
      </div>
      <div class="col-xl-10 fv-row fv-plugins-icon-container">
        <div class="col-md-12">
          <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input
              matInput
              formControlName="workflowname"
              name="workflowname"
            />
          </mat-form-field>
        </div>
        <div class="fv-plugins-message-container invalid-feedback"></div>
      </div>
    </div>
    <div class="row mb-8">
      <div class="col-xl-2 text-right">
        <div class="fs-6 fw-bold mt-5 mb-3">Stage</div>
      </div>
      <div class="col-xl-10 fv-row fv-plugins-icon-container">
        <div class="col-md-12">
          <mat-form-field appearance="outline">
            <mat-label>Stage</mat-label>
            <input matInput formControlName="workflowstage" />
          </mat-form-field>
        </div>
      </div>
    </div>
    <ng-container>
      <div class="row mb-8">
        <mat-accordion multi="true">
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h2 class="accordion-header fw-bold acc_button">Conditions</h2>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="row">
              <div class="col-xs-12">
                <table style="width: 100%" class="" role="presentation">
                  <tbody>
                    <tr
                      class="annotation-row"
                      data-annotation-type="Info Box Blue"
                      style="display: table-row"
                    >
                      <td
                        colspan="99"
                        class="annotation"
                        data-annotation-type="Info Box Blue"
                      >
                        <div
                          class="annotation-wrapper"
                          style="padding: 2px 0px 4px 4px"
                          role="note"
                        >
                          The If activity checks a condition and/or to determine
                          which transition a workflow follows: 'yes' or 'no'.
                          Use the 'Condition' builder to specify conditions that
                          must be true for the activity to transition along the
                          'yes' path
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="row mt-2" *ngIf="!ispublished">
                  <div class="col-xl-2 text-right">
                    <div class="fs-6 fw-bold mt-3 mb-3">Condition</div>
                  </div>
                  <div class="col-xl-10 fv-row fv-plugins-icon-container">
                    <div class="filter-row">
                      <button
                        id="btn_cond_and"
                        class="btn btn-secondary btn_psecondary me-2"
                      >
                        Add New Branch
                      </button>
                    </div>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-xl-2 text-right"></div>
                  <div class="col-xl-10 fv-row fv-plugins-icon-container">
                    <div class="filter-row">
                      <table
                        cellPadding="1"
                        border="0"
                        class="filerTable"
                        id="filerTable"
                        style="width: 100%"
                      >
                        <tbody>
                          <ng-container *ngIf="beginid == 0">
                            <tr class="rwand" data-main-id="clone-box-and-1">
                              <td class="rwand" colspan="6">
                                <div class="hr"></div>
                                <label class="pb-2 fw-bolder"
                                  ><span class="spnbranch"
                                    >Branch 1</span
                                  ></label
                                >
                              </td>
                              <td>
                                <div style="margin-bottom: -1px">
                                  <div class="hr"></div>
                                </div>
                                &nbsp;
                                <button
                                  alt="Remove"
                                  class="btn btn-secondary btn_psecondary btn_parent_del hide"
                                  aria-label="Remove this whole parent condition with childs."
                                  data-original-title="Remove this whole parent condition with childs."
                                >
                                  REMOVE
                                </button>
                              </td>
                            </tr>
                            <tr class="trcondand mb-2" id="clone-box-and-1">
                              <td colspan="2" style="min-width: 39px"></td>
                              <td class="col-md-3">
                                <select
                                  class="form-select btn_psecondary slc-cnd-name"
                                  tabindex="-1"
                                >
                                  <ng-container
                                    *ngFor="let control of formControls"
                                  >
                                    <option
                                      *ngIf="
                                        control?.controlType != 'groupcontrol'
                                      "
                                      [value]="control.controlType"
                                      [attr.data-id]="control.id"
                                    >
                                      {{ control.fieldName }}
                                    </option>
                                  </ng-container>
                                </select>
                              </td>
                              <td class="col-md-2" class="hide">
                                <select
                                  class="form-select btn_psecondary slc_subcontrols"
                                ></select>
                              </td>
                              <td class="col-md-2">
                                <select
                                  class="form-select btn_psecondary slc-cnd-type"
                                >
                                  <option
                                    *ngFor="let control of applicableStates"
                                  >
                                    {{ control }}
                                  </option>
                                </select>
                              </td>
                              <td style="min-width: 170px" class="hide">
                                <div class="input-group">
                                  <input
                                    type="text"
                                    class="form-control btn_psecondary txtcondvalue"
                                    name="txtcondvalue"
                                  />
                                </div>
                              </td>
                              <td style="min-width: 170px" class="hide">
                                <div class="input-group">
                                  <input
                                    type="number"
                                    class="form-control btn_psecondary txtcondvalue"
                                    name="txtcondvalue"
                                  />
                                </div>
                              </td>
                              <td style="min-width: 170px" class="hide">
                                <select
                                  class="form-select btn_psecondary"
                                ></select>
                              </td>
                              <td style="width: 170px" class="hide">
                                <div
                                  class="input-group date apdate"
                                  data-provide="datepicker"
                                >
                                  <input
                                    type="text"
                                    class="form-control"
                                    name="txtcondvalue"
                                    placeholder="mm/dd/yyyy"
                                  />
                                  <div class="input-group-append">
                                    <button
                                      class="btn btn_psecondary calendar"
                                      type="button"
                                    >
                                      <i
                                        class="fa fa-calendar"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                </div>
                              </td>
                              <td style="width: 150px" class="hide">
                                <div class="input-group date apdatetime">
                                  <input
                                    type="text"
                                    class="form-control"
                                    name="txtcondvalue"
                                    placeholder="hh:mm"
                                  />
                                  <span
                                    class="input-group-append datepickerbutton"
                                  >
                                    <span class="input-group-text"
                                      ><span class="fa fa-clock"></span
                                    ></span>
                                  </span>
                                </div>
                              </td>

                              <td style="white-space: nowrap">
                                <button
                                  alt="Add AND condition"
                                  class="btn btn-secondary btn_psecondary btn_child_and"
                                  aria-label="Add AND Condition To Condition 1: Approval group is"
                                  data-original-title="Add AND condition"
                                >
                                  AND
                                </button>
                                <button
                                  alt="Add OR Condition"
                                  class="btn btn-secondary btn_psecondary btn_child_or"
                                  aria-label="Add OR Condition To Condition 1: Approval group is"
                                  data-original-title="Add OR Condition"
                                >
                                  OR
                                </button>
                                <button
                                  type="button"
                                  class="btn btn-secondary btn_psecondary deleteButton"
                                  aria-label="Remove condition 1: Approval group is"
                                  data-original-title="Delete"
                                >
                                  <span class="icon-cross"></span
                                  ><span class="sr-only">'Delete'</span>
                                </button>
                              </td>
                            </tr>
                          </ng-container>
                          <ng-container *ngIf="beginid != 0">
                            <ng-container
                              *ngFor="let item of updateValue; let i = index"
                            >
                              <ng-container *ngIf="item.state == 'and'">
                                <ng-container
                                  *ngFor="
                                    let type of applicablestate;
                                    let ii = index
                                  "
                                >
                                  <ng-container
                                    *ngIf="type.controlType == item.control"
                                  >
                                    <tr
                                      class="rwand"
                                      [attr.data-main-id]="item.dataid"
                                    >
                                      <td class="rwand" colspan="6">
                                        <div class="hr"></div>
                                        <label
                                          class="pb-2 fw-bolder"
                                          *ngIf="i == 0"
                                          ><span class="spnbranch">{{
                                            item.branchname
                                          }}</span></label
                                        >
                                        <label
                                          class="pb-2 fw-bolder"
                                          *ngIf="i > 0"
                                          ><span class="spnbranch">{{
                                            item.branchname
                                          }}</span></label
                                        >
                                      </td>
                                      <td>
                                        <div style="margin-bottom: -1px">
                                          <div class="hr"></div>
                                        </div>
                                        <button
                                          *ngIf="!ispublished"
                                          alt="Remove"
                                          class="btn btn-secondary btn_psecondary btn_parent_del"
                                          aria-label="Remove this whole parent condition with childs."
                                          data-original-title="Remove this whole parent condition with childs."
                                        >
                                          REMOVE
                                        </button>
                                      </td>
                                    </tr>
                                    <tr
                                      class="trcondand mb-2"
                                      id="{{ item.dataid }}"
                                    >
                                      <td
                                        colspan="2"
                                        style="min-width: 39px"
                                      ></td>
                                      <td class="col-md-3">
                                        <select
                                          class="form-select btn_psecondary slc-cnd-name"
                                          tabindex="-1"
                                        >
                                          <option
                                            *ngFor="let control of formControls"
                                            [value]="control.controlType"
                                            [attr.data-id]="control.id"
                                            [selected]="
                                              item.control_id == control.id
                                            "
                                          >
                                            {{ control.fieldName }}
                                          </option>
                                        </select>
                                      </td>
                                      <td
                                        class="col-md-2"
                                        [ngClass]="
                                          item.subcontrol != 'undefined' &&
                                          item.subcontrol
                                            ? ''
                                            : 'hide'
                                        "
                                      >
                                        <select
                                          class="form-select btn_psecondary slc_subcontrols"
                                        >
                                          <ng-container
                                            *ngFor="
                                              let subelement of item.subcontrol
                                            "
                                          >
                                            <option
                                              value="{{ subelement.value }}"
                                            >
                                              {{ subelement.text }}
                                            </option>
                                          </ng-container>
                                        </select>
                                      </td>
                                      <td class="col-md-2">
                                        <select
                                          class="form-select btn_psecondary slc-cnd-type"
                                        >
                                          <option
                                            *ngFor="let itemtype of type.states"
                                            [selected]="
                                              item.condition_type.trim() ===
                                              itemtype.trim()
                                            "
                                          >
                                            {{ itemtype }}
                                          </option>
                                        </select>
                                      </td>
                                      <td
                                        style="min-width: 170px"
                                        [ngClass]="
                                          getColumnFive(
                                            item.control,
                                            item.condition_type
                                          )
                                        "
                                      >
                                        <div class="input-group">
                                          <input
                                            type="text"
                                            class="form-control btn_psecondary txtcondvalue"
                                            name="txtcondvalue"
                                            value="{{ item.value }}"
                                          />
                                        </div>
                                      </td>
                                      <td
                                        style="min-width: 170px"
                                        [ngClass]="
                                          getColumnSix(
                                            item.control,
                                            item.condition_type
                                          )
                                        "
                                      >
                                        <div class="input-group">
                                          <input
                                            type="number"
                                            class="form-control btn_psecondary txtcondvalue"
                                            name="txtcondvalue"
                                            value="{{ item.value }}"
                                          />
                                        </div>
                                      </td>
                                      <td
                                        style="min-width: 170px"
                                        [ngClass]="
                                          getColumnSeven(
                                            item.control,
                                            item.condition_type
                                          )
                                        "
                                      >
                                        <select
                                          class="form-select btn_psecondary"
                                        >
                                          <option>
                                            {{ item.value }}
                                          </option>
                                        </select>
                                      </td>
                                      <td
                                        style="min-width: 170px"
                                        [ngClass]="
                                          getColumnEight(
                                            item.control,
                                            item.condition_type
                                          )
                                        "
                                      >
                                        <div
                                          class="input-group date apdate"
                                          data-provide="datepicker"
                                        >
                                          <input
                                            type="text"
                                            class="form-control"
                                            name="txtcondvalue"
                                            placeholder="mm/dd/yyyy"
                                            value="{{ item.value }}"
                                          />
                                          <div class="input-group-append">
                                            <button
                                              class="btn btn_psecondary calendar"
                                              type="button"
                                            >
                                              <i
                                                class="fa fa-calendar"
                                                aria-hidden="true"
                                              ></i>
                                            </button>
                                          </div>
                                        </div>
                                      </td>
                                      <td
                                        style="min-width: 170px"
                                        [ngClass]="
                                          getColumnNine(
                                            item.control,
                                            item.condition_type
                                          )
                                        "
                                      >
                                        <div
                                          class="input-group date apdatetime"
                                        >
                                          <input
                                            type="text"
                                            class="form-control"
                                            name="txtcondvalue"
                                            placeholder="hh:mm"
                                            value="{{ item.value }}"
                                          />
                                          <span
                                            class="input-group-append datepickerbutton"
                                          >
                                            <span class="input-group-text"
                                              ><span class="fa fa-clock"></span
                                            ></span>
                                          </span>
                                        </div>
                                      </td>
                                      <td
                                        style="white-space: nowrap"
                                        *ngIf="!ispublished"
                                      >
                                        <button
                                          alt="Add AND condition"
                                          class="btn btn-secondary btn_psecondary btn_child_and"
                                          aria-label="Add AND Condition To Condition 1: Approval group is"
                                          data-original-title="Add AND condition"
                                        >
                                          AND
                                        </button>
                                        <button
                                          alt="Add OR Condition"
                                          class="btn btn-secondary btn_psecondary btn_child_or"
                                          aria-label="Add OR Condition To Condition 1: Approval group is"
                                          data-original-title="Add OR Condition"
                                        >
                                          OR
                                        </button>
                                        <button
                                          type="button"
                                          class="btn btn-secondary btn_psecondary deleteButton"
                                          aria-label="Remove condition 1: Approval group is"
                                          data-original-title="Delete"
                                        >
                                          <span class="icon-cross"></span
                                          ><span class="sr-only">'Delete'</span>
                                        </button>
                                      </td>
                                    </tr>
                                  </ng-container>
                                </ng-container>
                              </ng-container>
                              <ng-container *ngIf="item.state == 'or'">
                                <ng-container
                                  *ngFor="
                                    let type of applicablestate;
                                    let oo = index
                                  "
                                >
                                  <ng-container
                                    *ngIf="type.controlType == item.control"
                                  >
                                    <tr
                                      class="tror"
                                      id="{{ item.dataid }}"
                                      [attr.data-main-id]="item.dataid"
                                    >
                                      <td class="tror" colspan="6">
                                        <div class="hr"></div>
                                        <label class="pb-2 fw-bolder"
                                          ><span
                                            >"OR" Following conditions must be
                                            met</span
                                          ></label
                                        >
                                      </td>
                                      <td>
                                        <div style="margin-bottom: -1px">
                                          <div class="hr"></div>
                                        </div>
                                        <button
                                          *ngIf="!ispublished"
                                          alt="Remove"
                                          class="btn btn-secondary btn_psecondary btn_parent_del"
                                          aria-label="Remove this whole parent condition with childs."
                                          data-original-title="Remove this whole parent condition with childs."
                                        >
                                          REMOVE
                                        </button>
                                      </td>
                                    </tr>
                                    <tr
                                      class="trcondmor mb-2"
                                      id="{{ item.dataid }}"
                                    >
                                      <td
                                        colspan="2"
                                        style="min-width: 39px"
                                      ></td>

                                      <td class="col-md-3">
                                        <select
                                          class="form-select btn_psecondary slc-cnd-name"
                                          tabindex="-1"
                                        >
                                          <option
                                            *ngFor="let control of formControls"
                                            [value]="control.controlType"
                                            [attr.data-id]="control.id"
                                            [selected]="
                                              item.control_id == control.id
                                            "
                                          >
                                            {{ control.fieldName }}
                                          </option>
                                        </select>
                                      </td>
                                      <td
                                        class="col-md-2"
                                        [ngClass]="
                                          item.subcontrol != 'undefined' &&
                                          item.subcontrol
                                            ? ''
                                            : 'hide'
                                        "
                                      >
                                        <select
                                          class="form-select btn_psecondary slc_subcontrols"
                                        >
                                          <ng-container
                                            *ngFor="
                                              let subelement of item.subcontrol
                                            "
                                          >
                                            <option
                                              value="{{ subelement.value }}"
                                            >
                                              {{ subelement.text }}
                                            </option>
                                          </ng-container>
                                        </select>
                                      </td>
                                      <td class="col-md-2">
                                        <select
                                          class="form-select btn_psecondary slc-cnd-type"
                                        >
                                          <option
                                            *ngFor="let itemtype of type.states"
                                            [selected]="
                                              item.condition_type.trim() ===
                                              itemtype.trim()
                                            "
                                          >
                                            {{ itemtype }}
                                          </option>
                                        </select>
                                      </td>
                                      <td
                                        style="min-width: 170px"
                                        [ngClass]="
                                          getColumnFive(
                                            item.control,
                                            item.condition_type
                                          )
                                        "
                                      >
                                        <div class="input-group">
                                          <input
                                            type="text"
                                            class="form-control btn_psecondary txtcondvalue"
                                            name="txtcondvalue"
                                            value="{{ item.value }}"
                                          />
                                        </div>
                                      </td>
                                      <td
                                        style="min-width: 170px"
                                        [ngClass]="
                                          getColumnSix(
                                            item.control,
                                            item.condition_type
                                          )
                                        "
                                      >
                                        <div class="input-group">
                                          <input
                                            type="number"
                                            class="form-control btn_psecondary txtcondvalue"
                                            name="txtcondvalue"
                                            value="{{ item.value }}"
                                          />
                                        </div>
                                      </td>
                                      <td
                                        style="min-width: 170px"
                                        [ngClass]="
                                          getColumnSeven(
                                            item.control,
                                            item.condition_type
                                          )
                                        "
                                      >
                                        <select
                                          class="form-select btn_psecondary"
                                        >
                                          <option>
                                            {{ item.value }}
                                          </option>
                                        </select>
                                      </td>
                                      <td
                                        style="min-width: 170px"
                                        [ngClass]="
                                          getColumnEight(
                                            item.control,
                                            item.condition_type
                                          )
                                        "
                                      >
                                        <div
                                          class="input-group date apdate"
                                          data-provide="datepicker"
                                        >
                                          <input
                                            type="text"
                                            class="form-control"
                                            name="txtcondvalue"
                                            placeholder="mm/dd/yyyy"
                                            value="{{ item.value }}"
                                          />
                                          <div class="input-group-append">
                                            <button
                                              class="btn btn_psecondary calendar"
                                              type="button"
                                            >
                                              <i
                                                class="fa fa-calendar"
                                                aria-hidden="true"
                                              ></i>
                                            </button>
                                          </div>
                                        </div>
                                      </td>
                                      <td
                                        style="min-width: 170px"
                                        [ngClass]="
                                          getColumnNine(
                                            item.control,
                                            item.condition_type
                                          )
                                        "
                                      >
                                        <div
                                          class="input-group date apdatetime"
                                        >
                                          <input
                                            type="text"
                                            class="form-control"
                                            name="txtcondvalue"
                                            placeholder="hh:mm"
                                            value="{{ item.value }}"
                                          />
                                          <span
                                            class="input-group-append datepickerbutton"
                                          >
                                            <span class="input-group-text"
                                              ><span class="fa fa-clock"></span
                                            ></span>
                                          </span>
                                        </div>
                                      </td>
                                      <td
                                        style="white-space: nowrap"
                                        *ngIf="!ispublished"
                                      >
                                        <button
                                          alt="Add AND condition"
                                          class="btn btn-secondary btn_psecondary btn_child_and"
                                          aria-label="Add AND Condition To Condition 1: Approval group is"
                                          data-original-title="Add AND condition"
                                        >
                                          AND
                                        </button>
                                        <button
                                          alt="Add OR Condition"
                                          class="btn btn-secondary btn_psecondary btn_child_or"
                                          aria-label="Add OR Condition To Condition 1: Approval group is"
                                          data-original-title="Add OR Condition"
                                        >
                                          OR
                                        </button>
                                        <button
                                          type="button"
                                          class="btn btn-secondary btn_psecondary deleteButton"
                                          aria-label="Remove condition 1: Approval group is"
                                          data-original-title="Delete"
                                        >
                                          <span class="icon-cross"></span
                                          ><span class="sr-only">'Delete'</span>
                                        </button>
                                      </td>
                                    </tr>
                                  </ng-container>
                                </ng-container>
                              </ng-container>
                              <ng-container *ngIf="item.state == 'childand'">
                                <ng-container
                                  *ngFor="
                                    let type of applicablestate;
                                    let ii = index
                                  "
                                >
                                  <ng-container
                                    *ngIf="type.controlType == item.control"
                                  >
                                    <tr
                                      class="trcondand mb-2"
                                      [attr.data-parent-id]="item.dataparentid"
                                      [attr.data-child-id]="'dci-100' + i"
                                    >
                                      <td style="width: 25px">&nbsp;</td>
                                      <td
                                        style="
                                          width: 25px;
                                          text-align: center;
                                          padding-right: 5px;
                                          font-weight: bold;
                                        "
                                      >
                                        AND
                                      </td>
                                      <td class="col-md-3">
                                        <select
                                          class="form-select btn_psecondary slc-cnd-name"
                                          tabindex="-1"
                                        >
                                          <option
                                            *ngFor="let control of formControls"
                                            [value]="control.controlType"
                                            [attr.data-id]="control.id"
                                            [selected]="
                                              item.control_id == control.id
                                            "
                                          >
                                            {{ control.fieldName }}
                                          </option>
                                        </select>
                                      </td>
                                      <td
                                        class="col-md-2"
                                        [ngClass]="
                                          item.subcontrol != 'undefined' &&
                                          item.subcontrol
                                            ? ''
                                            : 'hide'
                                        "
                                      >
                                        <select
                                          class="form-select btn_psecondary slc_subcontrols"
                                        >
                                          <ng-container
                                            *ngFor="
                                              let subelement of item.subcontrol
                                            "
                                          >
                                            <option
                                              value="{{ subelement.value }}"
                                            >
                                              {{ subelement.text }}
                                            </option>
                                          </ng-container>
                                        </select>
                                      </td>
                                      <td class="col-md-2">
                                        <select
                                          class="form-select btn_psecondary slc-cnd-type"
                                        >
                                          <option
                                            *ngFor="let itemtype of type.states"
                                            [selected]="
                                              item.condition_type.trim() ===
                                              itemtype.trim()
                                            "
                                          >
                                            {{ itemtype }}
                                          </option>
                                        </select>
                                      </td>
                                      <td
                                        style="min-width: 170px"
                                        [ngClass]="
                                          getColumnFive(
                                            item.control,
                                            item.condition_type
                                          )
                                        "
                                      >
                                        <div class="input-group">
                                          <input
                                            type="text"
                                            class="form-control btn_psecondary txtcondvalue"
                                            name="txtcondvalue"
                                            value="{{ item.value }}"
                                          />
                                        </div>
                                      </td>
                                      <td
                                        style="min-width: 170px"
                                        [ngClass]="
                                          getColumnSix(
                                            item.control,
                                            item.condition_type
                                          )
                                        "
                                      >
                                        <div class="input-group">
                                          <input
                                            type="number"
                                            class="form-control btn_psecondary txtcondvalue"
                                            name="txtcondvalue"
                                            value="{{ item.value }}"
                                          />
                                        </div>
                                      </td>
                                      <td
                                        style="min-width: 170px"
                                        [ngClass]="
                                          getColumnSeven(
                                            item.control,
                                            item.condition_type
                                          )
                                        "
                                      >
                                        <select
                                          class="form-select btn_psecondary slcoptions"
                                        >
                                          <option>
                                            {{ item.value }}
                                          </option>
                                        </select>
                                      </td>
                                      <td
                                        style="min-width: 170px"
                                        [ngClass]="
                                          getColumnEight(
                                            item.control,
                                            item.condition_type
                                          )
                                        "
                                      >
                                        <div
                                          class="input-group date apdate"
                                          data-provide="datepicker"
                                        >
                                          <input
                                            type="text"
                                            class="form-control"
                                            name="txtcondvalue"
                                            placeholder="mm/dd/yyyy"
                                            value="{{ item.value }}"
                                          />
                                          <div class="input-group-append">
                                            <button
                                              class="btn btn_psecondary calendar"
                                              type="button"
                                            >
                                              <i
                                                class="fa fa-calendar"
                                                aria-hidden="true"
                                              ></i>
                                            </button>
                                          </div>
                                        </div>
                                      </td>
                                      <td
                                        style="min-width: 170px"
                                        [ngClass]="
                                          getColumnNine(
                                            item.control,
                                            item.condition_type
                                          )
                                        "
                                      >
                                        <div
                                          class="input-group date apdatetime"
                                        >
                                          <input
                                            type="text"
                                            class="form-control"
                                            name="txtcondvalue"
                                            placeholder="hh:mm"
                                            value="{{ item.value }}"
                                          />
                                          <span
                                            class="input-group-append datepickerbutton"
                                          >
                                            <span class="input-group-text"
                                              ><span class="fa fa-clock"></span
                                            ></span>
                                          </span>
                                        </div>
                                      </td>
                                      <td
                                        style="white-space: nowrap"
                                        *ngIf="!ispublished"
                                      >
                                        <button
                                          alt="Add AND condition"
                                          class="btn btn-secondary btn_psecondary btn_child_and"
                                          aria-label="Add AND Condition To Condition 1: Approval group is"
                                          data-original-title="Add AND condition"
                                        >
                                          AND
                                        </button>
                                        <button
                                          alt="Add OR Condition"
                                          class="btn btn-secondary btn_psecondary btn_child_or"
                                          aria-label="Add OR Condition To Condition 1: Approval group is"
                                          data-original-title="Add OR Condition"
                                        >
                                          OR
                                        </button>
                                        <button
                                          type="button"
                                          class="btn btn-secondary btn_psecondary deleteButton"
                                          aria-label="Remove condition 1: Approval group is"
                                          data-original-title="Delete"
                                        >
                                          <span class="icon-cross"></span
                                          ><span class="sr-only">'Delete'</span>
                                        </button>
                                      </td>
                                    </tr>
                                  </ng-container>
                                </ng-container>
                              </ng-container>
                              <ng-container *ngIf="item.state == 'childor'">
                                <ng-container
                                  *ngFor="
                                    let type of applicablestate;
                                    let oo = index
                                  "
                                >
                                  <ng-container
                                    *ngIf="type.controlType == item.control"
                                  >
                                    <tr
                                      class="trcondor mb-2"
                                      [attr.data-parent-id]="item.dataparentid"
                                      [attr.data-child-id]="'dci-100' + i"
                                    >
                                      <td style="width: 25px">&nbsp;</td>
                                      <td
                                        style="
                                          width: 25px;
                                          text-align: center;
                                          padding-right: 5px;
                                          font-weight: bold;
                                        "
                                      >
                                        OR
                                      </td>
                                      <td class="col-md-3">
                                        <select
                                          class="form-select btn_psecondary slc-cnd-name"
                                          tabindex="-1"
                                        >
                                          <option
                                            *ngFor="let control of formControls"
                                            [value]="control.controlType"
                                            [attr.data-id]="control.id"
                                            [selected]="
                                              item.control_id == control.id
                                            "
                                          >
                                            {{ control.fieldName }}
                                          </option>
                                        </select>
                                      </td>
                                      <td
                                        class="col-md-2"
                                        [ngClass]="
                                          item.subcontrol != 'undefined' &&
                                          item.subcontrol
                                            ? ''
                                            : 'hide'
                                        "
                                      >
                                        <select
                                          class="form-select btn_psecondary slc_subcontrols"
                                        >
                                          <ng-container
                                            *ngFor="
                                              let subelement of item.subcontrol
                                            "
                                          >
                                            <option
                                              value="{{ subelement.value }}"
                                            >
                                              {{ subelement.text }}
                                            </option>
                                          </ng-container>
                                        </select>
                                      </td>
                                      <td class="col-md-2">
                                        <select
                                          class="form-select btn_psecondary slc-cnd-type"
                                        >
                                          <option
                                            *ngFor="let itemtype of type.states"
                                            [selected]="
                                              item.condition_type.trim() ===
                                              itemtype.trim()
                                            "
                                          >
                                            {{ itemtype }}
                                          </option>
                                        </select>
                                      </td>
                                      <td
                                        style="min-width: 170px"
                                        [ngClass]="
                                          getColumnFive(
                                            item.control,
                                            item.condition_type
                                          )
                                        "
                                      >
                                        <div class="input-group">
                                          <input
                                            type="text"
                                            class="form-control btn_psecondary txtcondvalue"
                                            name="txtcondvalue"
                                            value="{{ item.value }}"
                                          />
                                        </div>
                                      </td>
                                      <td
                                        style="min-width: 170px"
                                        [ngClass]="
                                          getColumnSix(
                                            item.control,
                                            item.condition_type
                                          )
                                        "
                                      >
                                        <div class="input-group">
                                          <input
                                            type="number"
                                            class="form-control btn_psecondary txtcondvalue"
                                            name="txtcondvalue"
                                            value="{{ item.value }}"
                                          />
                                        </div>
                                      </td>
                                      <td
                                        style="min-width: 170px"
                                        [ngClass]="
                                          getColumnSeven(
                                            item.control,
                                            item.condition_type
                                          )
                                        "
                                      >
                                        <select
                                          class="form-select btn_psecondary"
                                        >
                                          <option>
                                            {{ item.value }}
                                          </option>
                                        </select>
                                      </td>
                                      <td
                                        style="min-width: 170px"
                                        [ngClass]="
                                          getColumnEight(
                                            item.control,
                                            item.condition_type
                                          )
                                        "
                                      >
                                        <div
                                          class="input-group date apdate"
                                          data-provide="datepicker"
                                        >
                                          <input
                                            type="text"
                                            class="form-control"
                                            name="txtcondvalue"
                                            placeholder="mm/dd/yyyy"
                                            value="{{ item.value }}"
                                          />
                                          <div class="input-group-append">
                                            <button
                                              class="btn btn_psecondary calendar"
                                              type="button"
                                            >
                                              <i
                                                class="fa fa-calendar"
                                                aria-hidden="true"
                                              ></i>
                                            </button>
                                          </div>
                                        </div>
                                      </td>
                                      <td
                                        style="min-width: 170px"
                                        [ngClass]="
                                          getColumnNine(
                                            item.control,
                                            item.condition_type
                                          )
                                        "
                                      >
                                        <div
                                          class="input-group date apdatetime"
                                        >
                                          <input
                                            type="text"
                                            class="form-control"
                                            name="txtcondvalue"
                                            placeholder="hh:mm"
                                            value="{{ item.value }}"
                                          />
                                          <span
                                            class="input-group-append datepickerbutton"
                                          >
                                            <span class="input-group-text"
                                              ><span class="fa fa-clock"></span
                                            ></span>
                                          </span>
                                        </div>
                                      </td>
                                      <td
                                        style="white-space: nowrap"
                                        *ngIf="!ispublished"
                                      >
                                        <button
                                          alt="Add AND condition"
                                          class="btn btn-secondary btn_psecondary btn_child_and"
                                          aria-label="Add AND Condition To Condition 1: Approval group is"
                                          data-original-title="Add AND condition"
                                        >
                                          AND
                                        </button>
                                        <button
                                          alt="Add OR Condition"
                                          class="btn btn-secondary btn_psecondary btn_child_or"
                                          aria-label="Add OR Condition To Condition 1: Approval group is"
                                          data-original-title="Add OR Condition"
                                        >
                                          OR
                                        </button>
                                        <button
                                          type="button"
                                          class="btn btn-secondary btn_psecondary deleteButton"
                                          aria-label="Remove condition 1: Approval group is"
                                          data-original-title="Delete"
                                        >
                                          <span class="icon-cross"></span
                                          ><span class="sr-only">'Delete'</span>
                                        </button>
                                      </td>
                                    </tr>
                                  </ng-container>
                                </ng-container>
                              </ng-container>
                            </ng-container>
                          </ng-container>
                        </tbody>
                      </table>
                      <table
                        cellPadding="1"
                        border="0"
                        class="MainAnd hide"
                        id="MainAnd"
                      >
                        <tbody>
                          <tr class="rwand">
                            <td class="rwand" colspan="6">
                              <div class="hr"></div>
                              <label class="pb-2 fw-bolder"
                                ><span class="spnbranch">Branch</span></label
                              >
                            </td>
                            <td>
                              <div style="margin-bottom: -1px">
                                <div class="hr"></div>
                              </div>
                              <button
                                alt="Remove"
                                class="btn btn-secondary btn_psecondary btn_parent_del"
                                aria-label="Remove this whole parent condition with childs."
                                data-original-title="Remove this whole parent condition with childs."
                              >
                                REMOVE
                              </button>
                            </td>
                          </tr>
                          <tr class="trcondand mb-2">
                            <td
                              colspan="2"
                              style="
                                min-width: 39px;
                                font-weight: bold;
                                text-align: right;
                                padding-right: 5px;
                              "
                            ></td>
                            <td class="col-md-3">
                              <select
                                class="form-select btn_psecondary slc-cnd-name"
                                tabindex="-1"
                              >
                                <option
                                  *ngFor="let control of formControls"
                                  [value]="control.controlType"
                                  [attr.data-id]="control.id"
                                >
                                  {{ control.fieldName }}
                                </option>
                              </select>
                            </td>
                            <td class="col-md-2" class="hide">
                              <select
                                class="form-select btn_psecondary slc_subcontrols"
                              ></select>
                            </td>
                            <td class="col-md-2">
                              <select
                                class="form-select btn_psecondary slc-cnd-type"
                              >
                                <option
                                  *ngFor="let control of applicableStates"
                                >
                                  {{ control }}
                                </option>
                              </select>
                            </td>
                            <td style="min-width: 170px" class="hide">
                              <div class="input-group">
                                <input
                                  type="text"
                                  class="form-control btn_psecondary txtcondvalue"
                                  name="txtcondvalue"
                                />
                              </div>
                            </td>
                            <td style="min-width: 170px" class="hide">
                              <div class="input-group">
                                <input
                                  type="number"
                                  class="form-control btn_psecondary txtcondvalue"
                                  name="txtcondvalue"
                                />
                              </div>
                            </td>
                            <td style="min-width: 170px" class="hide">
                              <select
                                class="form-select btn_psecondary"
                              ></select>
                            </td>
                            <td style="min-width: 170px" class="hide">
                              <div
                                class="input-group date apdate"
                                data-provide="datepicker"
                              >
                                <input
                                  name="txtcondvalue"
                                  class="form-control"
                                  placeholder="mm/dd/yyyy"
                                  readonly
                                />
                                <div class="input-group-append">
                                  <button
                                    class="btn btn_psecondary calendar"
                                    type="button"
                                  >
                                    <i
                                      class="fa fa-calendar"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </div>
                            </td>
                            <td style="min-width: 170px" class="hide">
                              <div class="input-group date apdatetime">
                                <input
                                  type="text"
                                  class="form-control"
                                  name="txtcondvalue"
                                  placeholder="hh:mm"
                                />
                                <span
                                  class="input-group-append datepickerbutton"
                                >
                                  <span class="input-group-text"
                                    ><span class="fa fa-clock"></span
                                  ></span>
                                </span>
                              </div>
                            </td>
                            <td style="white-space: nowrap">
                              <button
                                alt="Add AND condition"
                                class="btn btn-secondary btn_psecondary btn_child_and"
                                aria-label="Add AND Condition To Condition 1: Approval group is"
                                data-original-title="Add AND condition"
                              >
                                AND
                              </button>
                              <button
                                alt="Add OR Condition"
                                class="btn btn-secondary btn_psecondary btn_child_or"
                                aria-label="Add OR Condition To Condition 1: Approval group is"
                                data-original-title="Add OR Condition"
                              >
                                OR
                              </button>
                              <button
                                type="button"
                                class="btn btn-secondary btn_psecondary deleteButton"
                                aria-label="Remove condition 1: Approval group is"
                                data-original-title="Delete"
                              >
                                <span class="icon-cross"></span
                                ><span class="sr-only">'Delete'</span>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table
                        cellPadding="1"
                        border="0"
                        class="MainOrTable hide"
                      >
                        <tbody>
                          <tr class="tror">
                            <td class="tror" colspan="6">
                              <div class="hr"></div>
                              <label class="pb-2 fw-bolder"
                                ><span
                                  >"OR" following conditions must be met</span
                                ></label
                              >
                            </td>
                            <td>
                              <div style="margin-bottom: -1px">
                                <div class="hr"></div>
                              </div>
                              <button
                                alt="Remove"
                                class="btn btn-secondary btn_psecondary btn_parent_del"
                                aria-label="Remove this whole parent condition with childs."
                                data-original-title="Remove this whole parent condition with childs."
                              >
                                REMOVE
                              </button>
                            </td>
                          </tr>
                          <tr class="trcondmor mb-2" id="clone-box-mor-1">
                            <td
                              colspan="2"
                              style="
                                min-width: 39px;
                                font-weight: bold;
                                text-align: right;
                                padding-right: 5px;
                              "
                            ></td>

                            <td class="col-md-3">
                              <select
                                class="form-select btn_psecondary slc-cnd-name"
                                tabindex="-1"
                              >
                                <option
                                  *ngFor="let control of formControls"
                                  [value]="control.controlType"
                                  [attr.data-id]="control.id"
                                >
                                  {{ control.fieldName }}
                                </option>
                              </select>
                            </td>
                            <td class="col-md-2" class="hide">
                              <select
                                class="form-select btn_psecondary slc_subcontrols"
                              ></select>
                            </td>
                            <td class="col-md-2">
                              <select
                                class="form-select btn_psecondary slc-cnd-type"
                              >
                                <option
                                  *ngFor="let control of applicableStates"
                                  value="="
                                >
                                  {{ control }}
                                </option>
                              </select>
                            </td>
                            <td style="min-width: 170px" class="hide">
                              <div class="input-group">
                                <input
                                  type="text"
                                  class="form-control btn_psecondary"
                                  name="txtcondvalue"
                                />
                              </div>
                            </td>
                            <td style="min-width: 170px" class="hide">
                              <div class="input-group">
                                <input
                                  type="number"
                                  class="form-control btn_psecondary"
                                  name="txtcondvalue"
                                />
                              </div>
                            </td>
                            <td style="min-width: 170px" class="hide">
                              <select
                                class="form-select btn_psecondary"
                              ></select>
                            </td>
                            <td style="min-width: 170px" class="hide">
                              <div
                                class="input-group date apdate"
                                data-provide="datepicker"
                              >
                                <input
                                  class="form-control"
                                  placeholder="mm/dd/yyyy"
                                  name="txtcondvalue"
                                  readonly
                                />
                                <div class="input-group-append">
                                  <button
                                    class="btn btn_psecondary calendar"
                                    type="button"
                                  >
                                    <i
                                      class="fa fa-calendar"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </div>
                            </td>
                            <td style="min-width: 170px" class="hide">
                              <div class="input-group date apdatetime">
                                <input
                                  type="text"
                                  class="form-control"
                                  name="txtcondvalue"
                                  placeholder="hh:mm"
                                />
                                <span
                                  class="input-group-append datepickerbutton"
                                >
                                  <span class="input-group-text"
                                    ><span class="fa fa-clock"></span
                                  ></span>
                                </span>
                              </div>
                            </td>
                            <td style="white-space: nowrap">
                              <button
                                alt="Add AND condition"
                                class="btn btn-secondary btn_psecondary btn_child_and"
                                aria-label="Add AND Condition To Condition 1: Approval group is"
                                data-original-title="Add AND condition"
                              >
                                AND
                              </button>
                              <button
                                alt="Add OR Condition"
                                class="btn btn-secondary btn_psecondary btn_child_or"
                                aria-label="Add OR Condition To Condition 1: Approval group is"
                                data-original-title="Add OR Condition"
                              >
                                OR
                              </button>
                              <button
                                type="button"
                                class="btn btn-secondary btn_psecondary deleteButton"
                                aria-label="Remove condition 1: Approval group is"
                                data-original-title="Delete"
                              >
                                <span class="icon-cross"></span
                                ><span class="sr-only">'Delete'</span>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table
                        cellPadding="1"
                        border="0"
                        class="filterAndTable hide"
                      >
                        <tbody>
                          <tr class="trcondand mb-2">
                            <td>&nbsp;</td>
                            <td
                              style="
                                text-align: center;
                                padding-right: 5px;
                                font-weight: bold;
                              "
                            >
                              AND
                            </td>
                            <td class="col-md-3">
                              <select
                                class="form-select btn_psecondary slc-cnd-name"
                                tabindex="-1"
                              >
                                <option
                                  *ngFor="let control of formControls"
                                  [value]="control.controlType"
                                  [attr.data-id]="control.id"
                                >
                                  {{ control.fieldName }}
                                </option>
                              </select>
                            </td>
                            <td class="col-md-2" class="hide">
                              <select
                                class="form-select btn_psecondary slc_subcontrols"
                              ></select>
                            </td>
                            <td class="col-md-2">
                              <select
                                class="form-select btn_psecondary slc-cnd-type"
                              >
                                <option
                                  *ngFor="let control of applicableStates"
                                  value="="
                                >
                                  {{ control }}
                                </option>
                              </select>
                            </td>
                            <td style="min-width: 170px" class="hide">
                              <div class="input-group">
                                <input
                                  type="text"
                                  class="form-control btn_psecondary"
                                  name="txtcondvalue"
                                />
                              </div>
                            </td>
                            <td style="min-width: 170px" class="hide">
                              <div class="input-group">
                                <input
                                  type="number"
                                  class="form-control btn_psecondary"
                                  name="txtcondvalue"
                                />
                              </div>
                            </td>
                            <td style="min-width: 170px" class="hide">
                              <select
                                class="form-select btn_psecondary slc_dropdown"
                              ></select>
                            </td>
                            <td style="min-width: 170px" class="hide">
                              <div
                                class="input-group date apdate"
                                data-provide="datepicker"
                              >
                                <input
                                  class="form-control"
                                  placeholder="mm/dd/yyyy"
                                  name="txtcondvalue"
                                  readonly
                                />
                                <div class="input-group-append">
                                  <button
                                    class="btn btn_psecondary calendar"
                                    type="button"
                                  >
                                    <i
                                      class="fa fa-calendar"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </div>
                            </td>
                            <td style="min-width: 170px" class="hide">
                              <div class="input-group date apdatetime">
                                <input
                                  type="text"
                                  class="form-control"
                                  name="txtcondvalue"
                                  placeholder="hh:mm"
                                />
                                <span
                                  class="input-group-append datepickerbutton"
                                >
                                  <span class="input-group-text"
                                    ><span class="fa fa-clock"></span
                                  ></span>
                                </span>
                              </div>
                            </td>
                            <td style="white-space: nowrap">
                              <button
                                alt="Add AND condition"
                                class="btn btn-secondary btn_psecondary btn_child_and"
                                aria-label="Add AND Condition To Condition 1: Approval group is"
                                data-original-title="Add AND condition"
                              >
                                AND
                              </button>
                              <button
                                alt="Add OR Condition"
                                class="btn btn-secondary btn_psecondary btn_child_or"
                                aria-label="Add OR Condition To Condition 1: Approval group is"
                                data-original-title="Add OR Condition"
                              >
                                OR
                              </button>
                              <button
                                type="button"
                                class="btn btn-secondary btn_psecondary deleteButton"
                                aria-label="Remove condition 1: Approval group is"
                                data-original-title="Delete"
                              >
                                <span class="icon-cross"></span
                                ><span class="sr-only">'Delete'</span>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table
                        cellPadding="1"
                        border="0"
                        class="filterOrTable hide"
                      >
                        <tbody>
                          <tr class="trcondor mb-2">
                            <td>&nbsp;</td>
                            <td
                              style="
                                text-align: center;
                                padding-right: 5px;
                                font-weight: bold;
                              "
                            >
                              OR
                            </td>
                            <td class="col-md-3">
                              <select
                                class="form-select btn_psecondary slc-cnd-name"
                                tabindex="-1"
                              >
                                <option
                                  *ngFor="let control of formControls"
                                  [value]="control.controlType"
                                  [attr.data-id]="control.id"
                                >
                                  {{ control.fieldName }}
                                </option>
                              </select>
                            </td>
                            <td class="col-md-2" class="hide">
                              <select
                                class="form-select btn_psecondary slc_subcontrols"
                              ></select>
                            </td>
                            <td class="col-md-2">
                              <select
                                class="form-select btn_psecondary slc-cnd-type"
                              >
                                <option
                                  *ngFor="let control of applicableStates"
                                  value="="
                                >
                                  {{ control }}
                                </option>
                              </select>
                            </td>
                            <td style="min-width: 170px" class="hide">
                              <div class="input-group">
                                <input
                                  type="text"
                                  class="form-control btn_psecondary"
                                  name="txtcondvalue"
                                />
                              </div>
                            </td>
                            <td style="min-width: 170px" class="hide">
                              <div class="input-group">
                                <input
                                  type="number"
                                  class="form-control btn_psecondary"
                                  name="txtcondvalue"
                                />
                              </div>
                            </td>
                            <td style="min-width: 170px" class="hide">
                              <select
                                class="form-select btn_psecondary slc_dropdown"
                              ></select>
                            </td>
                            <td style="min-width: 170px" class="hide">
                              <div
                                class="input-group date apdate"
                                data-provide="datepicker"
                              >
                                <input
                                  class="form-control"
                                  placeholder="mm/dd/yyyy"
                                  name="txtcondvalue"
                                  readonly
                                />
                                <div class="input-group-append">
                                  <button
                                    class="btn btn_psecondary calendar"
                                    type="button"
                                  >
                                    <i
                                      class="fa fa-calendar"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </div>
                            </td>
                            <td style="min-width: 170px" class="hide">
                              <div class="input-group date apdatetime">
                                <input
                                  type="text"
                                  class="form-control"
                                  name="txtcondvalue"
                                  placeholder="hh:mm"
                                />
                                <span
                                  class="input-group-append datepickerbutton"
                                >
                                  <span class="input-group-text"
                                    ><span class="fa fa-clock"></span
                                  ></span>
                                </span>
                              </div>
                            </td>
                            <td style="white-space: nowrap">
                              <button
                                alt="Add AND condition"
                                class="btn btn-secondary btn_psecondary btn_child_and"
                                aria-label="Add AND Condition To Condition 1: Approval group is"
                                data-original-title="Add AND condition"
                              >
                                AND
                              </button>
                              <button
                                alt="Add OR Condition"
                                class="btn btn-secondary btn_psecondary btn_child_or"
                                aria-label="Add OR Condition To Condition 1: Approval group is"
                                data-original-title="Add OR Condition"
                              >
                                OR
                              </button>
                              <button
                                type="button"
                                class="btn btn-secondary btn_psecondary deleteButton"
                                aria-label="Remove condition 1: Approval group is"
                                data-original-title="Delete"
                              >
                                <span class="icon-cross"></span
                                ><span class="sr-only">'Delete'</span>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h2 class="accordion-header fw-bold acc_button">
                  Default Condition
                </h2>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
              <div class="col-xs-12">
                <div class="d-flex flex-stack col-md-11">
                  <div class="me-5">
                    <div class="fs-7 fw-bold text-muted">
                      This default condition is triggered when none of the
                      specified conditions within the conditional branch are
                      met.
                    </div>
                  </div>
                  <label
                    class="form-check form-switch form-check-custom form-check-solid"
                  >
                    <input
                      formControlName="defCond"
                      class="form-check-input"
                      type="checkbox"
                      value="1"
                      [checked]="defCond ? true : false"
                      (change)="defaultCondition($event)"
                    />
                  </label>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </ng-container>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <a href="javascript:void(0);" mat-raised-button (click)="close()">Close</a>
  <a
    *ngIf="beginid == 0 && !ispublished"
    href="javascript:void(0);"
    mat-raised-button
    color="primary"
    (click)="saveform('save')"
    >Save</a
  >
  <a
    *ngIf="beginid != 0 && !ispublished"
    href="javascript:void(0);"
    mat-raised-button
    color="primary"
    (click)="saveform('update')"
    >Update</a
  >
</mat-dialog-actions>
